import React from 'react';
import PropTypes, { bool, ReactNodeLike } from 'prop-types';
import classNames from 'classnames';

import { Collapse } from 'react-collapse';

import Check from '../../../icons/line/Check';

import { Indicator, Input, Label, Wrapper, ErrorMessage } from './styled.components';

interface Props {
  checked: boolean;
  name: string;
  label: ReactNodeLike;
  error: ReactNodeLike;
  onToggle: (...args: any[]) => any;
  InputProps: any;
  LabelProps: any;
  IndicatorProps: any;
};

/**
 * Checkboxes allow the user to select one or more items from a set.
 */
const Checkbox: React.FC<Props> = ({
  checked,
  name,
  label,
  error,
  onToggle,
  InputProps,
  LabelProps,
  IndicatorProps,
  ...rest
}) => (
  <>
    <Wrapper
      className={classNames({
        checked,
        error: !!error,
      })}
      {...rest}
    >
      <Input type="checkbox" name={name} onChange={onToggle} checked={checked} {...InputProps} />
      <Indicator {...IndicatorProps}>
        <Check />
      </Indicator>
      {label && <Label {...LabelProps}>{label}</Label>}
    </Wrapper>
    <Collapse isOpened={!!error}>
      <ErrorMessage>{error}</ErrorMessage>
    </Collapse>
  </>
);

export default Checkbox;
