import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';

import Box from '../../common/components/Box';
import Button from '../../common/components/Button';
import Container from '../../common/components/Container';
import Card from '../../common/components/Card';
import Typography from '../../common/components/Typography';
import { Formik, Form } from 'formik';
import Input from '../../common/components/Formik/Input';
import Checkbox from '../../common/components/Formik/Checkbox';

import data from '../../data';
import Liquid from '../../components/Liquids/B';

const DownloadUpdateContainer = (props) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [serverResponse, setServerResponse] = useState(``);

  return (
    <DownloadUpdate
      {...props}
      form={{
        ...DownloadUpdate.defaultProps.form,
        hasSuccessStatus: isSuccess,
        hasErrorStatus: isError,
        onSubmit: async (values, { setSubmitting }) => {
          //e.preventDefault();
          setIsSuccess(false);
          setIsError(false);

          setSubmitting(true);

          try {
            const response = await window.fetch(`/api/createTicketForDownload`, {
              method: `POST`,
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(values),
            }).then(res => res.json());
            setServerResponse(response);
            console.warn(response);
            if (response?.send) {
              setIsSuccess(true);
            } else {
              setIsError(true);
            }
          } catch (_) {
            setIsError(true);
          }
        },
      }}
    />
  );
};

const DownloadUpdate = ({
  WrapperProps,
  ContainerProps,
  LiquidProps,
  CardProps,
  TitleProps,
  TextProps,
  InputProps,
  CheckboxProps,
  ButtonProps,
  name,
  title,
  text,
  sendButtonText,
  inputPlaceholder,
  LiquidComponent,
  form, 
  SuccessMessageProps, 
  SuccessMessageTextProps, 
  successMessage, 
  ErrorMessageProps, 
  ErrorMessageTextProps, 
  errorMessage,
}) => (
  <Reveal ssrReveal ssrFadeout effect="liquid" duration={600}>
    <Box {...WrapperProps} name={name}>
      <Container {...ContainerProps}>
        <LiquidComponent {...LiquidProps} />
        <Card {...CardProps}>
          <Fade duration={600} collapse when={!!form.hasSuccessStatus}>
            <Box pb={3}>
              <Card {...SuccessMessageProps}>
                <Typography {...SuccessMessageTextProps}>{successMessage}</Typography>
              </Card>
            </Box>
          </Fade>
          <Fade duration={600} collapse when={!!form.hasErrorStatus}>
            <Box pb={3}>
              <Card {...ErrorMessageProps}>
                <Typography {...ErrorMessageTextProps}>{errorMessage}</Typography>
              </Card>
            </Box>
          </Fade>
          <Formik            
            validationSchema={form.validationSchema}
            onSubmit={form.onSubmit}
            initialValues={form.fields.reduce(
              (acc, field) => ({
                ...acc,
                [field.name]: field.initialValue,
              }),
              {},
            )}
          >
            <Form>
              <Fade bottom cascade duration={600}>
                <Typography {...TitleProps}>{title}</Typography>
                <Typography {...TextProps}>{text}</Typography>
                <Input
                  name="email"
                  {...InputProps}
                  placeholder={form.fields[0].placeholder}
                  suffix={(
                    <Button variant="primary" type="submit" {...ButtonProps}>
                      {sendButtonText}
                    </Button>
                  )}
                />
                <Checkbox 
                  name="policyCheck"
                  {...CheckboxProps}
                  label="Ho letto, compreso e accettato i termini e condizioni"
                  suffix={(
                    <Typography {...TextProps}>{"Ho letto, compreso e accettato i termini e condizioni"}</Typography>
                  )}
                  style={{WebKitAppearance:'checkbox'}}
                  onToggle={args=>console.log(args)}
                />
              </Fade>
            </Form>
          </Formik>
        </Card>
      </Container>
    </Box>
  </Reveal>
);
DownloadUpdate.propTypes = {
  /**
   * Name of container, can be used for anchors
   */
  name: PropTypes.string.isRequired,
  /**
   * Component container props
   * @See ../common/components/Container
   */
  ContainerProps: PropTypes.object,
  /**
   * Main wrapper props
   * @See ../common/components/Box
   */
  WrapperProps: PropTypes.object,
  /**
   * Props with liquid in background
   */
  LiquidProps: PropTypes.object,
  /**
   * Props for card wrapper
   */
  CardProps: PropTypes.object,
  /**
   * Title text props
   * @See ../common/components/Typography
   */
  TitleProps: PropTypes.object,
  /**
   * Main text props
   * @See ../common/components/Typography
   */
  TextProps: PropTypes.object,
  /**
   * Input email props
   * @See ../common/components/Input
   */
  InputProps: PropTypes.object,
  /**
   * Input checkbox props
   * @See ../common/components/Input
   */
   CheckboxProps: PropTypes.object,
  /**
   * Send button props
   * @See ../common/components/Button
   */
  ButtonProps: PropTypes.object,
  /**
   * Title node of component
   */
  title: PropTypes.node,
  /**
   * Main description
   */
  text: PropTypes.node,
  /**
   * Email input placeholder
   */
  inputPlaceholder: PropTypes.string,
  /**
   * Text for button in form
   */
  sendButtonText: PropTypes.node,
  /**
   * Liquid component in background
   */
  LiquidComponent: PropTypes.elementType,

  /**
   * Send button content
   */
  sendButtonText: PropTypes.node,
  /**
   * Form details
   */
  form: PropTypes.shape({
    /**
     * Formik validation schema
     * @see https://jaredpalmer.com/formik/docs/guides/validation
     */
    validationSchema: PropTypes.object,
    /**
     * Form submit function
     */
    onSubmit: PropTypes.func,
    /**
     * List of fields
     */
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Name of field
         */
        name: PropTypes.string.isRequired,
        /**
         * Type of field
         */
        type: PropTypes.string,
        /**
         * Placeholder for field
         */
        placeholder: PropTypes.string,
        /**
         * Initial value used in formik
         */
        initialValue: PropTypes.string,
        /**
         * Initial value used in formik
         */
        fullSize: PropTypes.bool,
      }),
    ),
  }),
  FormProps: PropTypes.object,
  /**
   * Props of success message
   * @See ../common/src/components/Box
   */
  SuccessMessageProps: PropTypes.object,
  /**
   * Props of success message text
   * @See ../common//components/Box
   */
  SuccessMessageTextProps: PropTypes.object,
  /**
   * Props of error message
   * @See ../common/components/Box
   */
  ErrorMessageProps: PropTypes.object,
  /**
   * Props of error message text
   * @See ../common/components/Box
   */
  ErrorMessageTextProps: PropTypes.object,
};
DownloadUpdate.defaultProps = {
  WrapperProps: {
    overflow: 'hidden',
    py: {
      _: 60,
      lg: 150,
    },
  },
  ContainerProps: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pt: {
      _: 0,
      lg: 148,
    },
    pb: {
      _: 0,
      lg: 163,
    },
  },
  LiquidProps: {
    position: 'absolute',
    maxWidth: {
      _: 'none',
      lg: 770,
    },
    width: {
      _: '130%',
      lg: '100%',
    },
    zIndex: -1,
    transform: 'scale(1, -1)',
  },
  CardProps: {
    borderRadius: {
      _: 30,
      lg: 90,
    },
    p: {
      _: 30,
      lg: 60,
    },
    width: '100%',
    maxWidth: 570,
  },
  TitleProps: {
    as: 'h2',
    variant: 'h2',
    mb: 2,
  },
  TextProps: {
    variant: 'body2',
    color: 'gray.1',
  },
  InputProps: {
    WrapperProps: {
      mt: 4,
    },
    InputWrapperProps: {
      borderRadius: 30,
    },
  },
  CheckboxProps: {
    mt: '2',
    InputProps: {},
    LabelProps: {},
    IndicatorProps: {},
  },
  ButtonProps: {
    m: '4px',
  },
  LiquidComponent: Liquid,

  SuccessMessageProps: {
    bg: 'green.6',
    color: 'gray.0',
  },
  SuccessMessageTextProps: {
    fontWeight: 600,
  },
  ErrorMessageProps: {
    bg: 'red.6',
    color: 'gray.0',
  },
  ErrorMessageTextProps: {
    fontWeight: 600,
  },
  ...data.downloadUpdate,
};

export default DownloadUpdateContainer;
