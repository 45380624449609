import React from 'react';

import { useField } from 'formik';

import Checkbox from '../Checkbox';

const FormikCheckbox: React.FC<any> = (props) => {
  const [field, meta] = useField(props);

  return <Checkbox {...props} {...field} touched={meta.touched} error={meta.touched && meta.error} />;
};

export default FormikCheckbox;
